import { Routes, Route } from "react-router-dom";
import Login from "./admin/screens/login";
import Dashboard from "./admin/screens/dashboard";
import Home from "./shop/home/home";
import CategoriesCreate from "./admin/screens/categories/create";
import SubcategoriesList from "./admin/screens/subcategories/list";
import CategoriesList from "./admin/screens/categories/list";
import ProductsCreate from "./admin/screens/products/create";
import ProductsList from "./admin/screens/products/list";
import SubcategoriesCreate from "./admin/screens/subcategories/create";
import WarehousesList from "./admin/screens/warehouses/list";
import WarehousesCreate from "./admin/screens/warehouses/create";
import SuppliersList from "./admin/screens/suppliers/list";
import SuppliersCreate from "./admin/screens/suppliers/create";
import BillersList from "./admin/screens/billers/list";
import BillersCreate from "./admin/screens/billers/create";
import StockCreate from "./admin/screens/stock/create";
import StockList from "./admin/screens/stock/list";
import SalesList from "./admin/screens/sales/list";
import UsersList from "./admin/screens/users/list";
import NotificationsList from "./admin/screens/notifications/list";
import DeliveryGuysList from "./admin/screens/delivery_guys/list";
import DeliveryGuysCreate from "./admin/screens/delivery_guys/create";
import BlogList from "./admin/screens/blog/list";
import BlogCreate from "./admin/screens/blog/create";
import ExpensesList from "./admin/screens/expenses/list";
import ExpensesCreate from "./admin/screens/expenses/create";
import BillerProductsCreate from "./admin/screens/biller_products/create";
import BillerProductsList from "./admin/screens/biller_products/list";
import Categories from "./shop/categories/categories";
import List from "./admin/screens/promotions/list";
import PromotionsCreate from "./admin/screens/promotions/create";
import StockHistory from "./admin/screens/stock/history";
import SectionList from "./admin/screens/section/list";
import SectionCreate from "./admin/screens/section/create";
import StockTransfer from "./admin/screens/stock/transfer";
import AlertPage from "./admin/screens/alerts/alert_page";
import InventoryAlerts from "./admin/screens/alerts/inventory_alerts";
import QuantityAlerts from "./admin/screens/alerts/quantity_alerts";
import PurchaseOrderList from "./admin/screens/purchase_orders/list";
import PurchaseOrderManual from "./admin/screens/purchase_orders/manual";
import AddProductToSubcategory from "./admin/screens/subcategories/add_product";
import { useEffect } from "react";
import PurchaseOrderView from "./admin/screens/purchase_orders/view";
import DayClosure from "./admin/screens/closures/day_closure";
import ClosureList from "./admin/screens/closures/closure_list";
import ClosureDetail from "./admin/screens/closures/closure_detail";
import MultiTransfer from "./admin/screens/stock/multi_transfer";
import SubcategoryAlerts from "./admin/screens/alerts/subcategory_alerts";
import SystemSettingsView from "./admin/screens/system/view";
import SaleView from "./admin/screens/sales/view";
import SalesDetail from "./admin/screens/sales/detail";
import PurchaseOrderDetailed from "./admin/screens/purchase_orders/detailed";
import SubcategoryDetailed from "./admin/screens/subcategories/detailed";
import TransferHistory from "./admin/screens/stock/transfer_history";
import HistoricExpenseList from "./admin/screens/closures/expense_list";
import HistoricFeeList from "./admin/screens/closures/fee_list";
import CGV from "./shop/components/footer/CGV/cgv";
import Contact from "./shop/components/footer/contact/contact";
import ShopProducts from "./shop/products/products";
import Profile from "./shop/profile/profile";
import PurchaseOrderAutomatic from "./admin/screens/purchase_orders/automatic";
import Locate from "./shop/locate/locate";
import MyOrders from "./shop/my_orders/my_orders";
import ShopBillerProducts from "./shop/biller_products/biller_products";
import ShopBillers from "./shop/billers/billers";
import ComboList from "./admin/screens/combos/list";
import ComboCreate from "./admin/screens/combos/create";
import SalesProducts from "./admin/screens/sales/products";
import AdminAccountsList from "./admin/screens/admin_accounts/list";
import AdminCreate from "./admin/screens/admin_accounts/create";
import ContactMessagesList from "./admin/screens/contact_messages/list";
import EmailTemplates from "./admin/screens/templates/list";
import EmailTemplatesCreate from "./admin/screens/templates/create";
import BillerStats from "./admin/screens/billers/stats";
import Checkout from "./shop/checkout/checkout";
import Confirmation from "./shop/confirmation/confirmation";
import DynamicDayClosure from "./admin/screens/closures/dynamic_closure";
import PurchaseOrderProducts from "./admin/screens/purchase_orders/products";
import AppLink from "./shop/app_link/app_link";
import ImageAlerts from "./admin/screens/alerts/image_alerts";
import PurchaseOrderEdit from "./admin/screens/purchase_orders/update";
import AutoMultiTransfer from "./admin/screens/stock/auto_transfert";
import CancelledSalesList from "./admin/screens/sales/cancelled";
import MonthlyClosureList from "./admin/screens/closures/closure_mensuel";

function LoginRedirect() {
  useEffect(() => {
    window.location.replace("/locate");
  }, []);
  return <div></div>;
}

export default function RouteContainer() {
  let authenticated = localStorage.getItem("access_token") !== null;
  let located = localStorage.hasOwnProperty("district");

  return (
    <Routes>
      <Route path="/locate" element={<Locate />}>
        Locate
      </Route>
      <Route path={"/app"} element={<AppLink />}>
        App
      </Route>
      <Route path={"/appli_link"} element={<AppLink />}>
        App
      </Route>

      {located && (
        <>
          <Route path="/:biller_slug" element={<ShopBillerProducts />}>
            ShopBillerProducts
          </Route>
          <Route path="/" element={<Home />}>
            Home
          </Route>
          <Route path="/checkout" element={<Checkout />}>
            Checkout
          </Route>
          <Route path="/confirmation" element={<Confirmation />}>
            Confirmation
          </Route>
          <Route path="/categories" element={<Categories />}>
            Categories
          </Route>
          <Route path="/epicerie-en-ligne" element={<Categories />}>
            Categories
          </Route>
          <Route path="/restaurants-et-autres" element={<ShopBillers />}>
            ShopBillers
          </Route>
          <Route path="/nos-commerces" element={<ShopBillers />}>
            ShopBillers
          </Route>
          <Route path="/billers" element={<ShopBillers />}>
            ShopBillers
          </Route>
          <Route
            path="/biller_products/:biller_slug"
            element={<ShopBillerProducts />}
          >
            ShopBillerProducts
          </Route>
          <Route path="/products" element={<ShopProducts />}>
            ShopProducts
          </Route>
          <Route path="/profile" element={<Profile />}>
            Profile
          </Route>
          <Route path="/my_orders" element={<MyOrders />}>
            MyOrders
          </Route>
          <Route path="/cgv-cgu" element={<CGV />}>
            CGV
          </Route>
          <Route path="/contact" element={<Contact />}>
            Contact
          </Route>
        </>
      )}

      {authenticated && (
        <>
          {/* ADMIN */}
          <Route path="/admin/dashboard" element={<Dashboard />}>
            Dashboard
          </Route>

          <Route path="/admin/products" element={<ProductsList />}>
            ProductsList
          </Route>
          <Route path="/admin/combos" element={<ComboList />}>
            ComboList
          </Route>
          <Route path="/admin/combos/create" element={<ComboCreate />}>
            ComboCreate
          </Route>
          <Route path="/admin/products/create" element={<ProductsCreate />}>
            ProductsCreate
          </Route>
          <Route path="/admin/billerproducts" element={<BillerProductsList />}>
            BillerProductsList
          </Route>
          <Route
            path="/admin/billerproducts/create"
            element={<BillerProductsCreate />}
          >
            BillerProductsCreate
          </Route>
          <Route path="/admin/sections" element={<SectionList />}>
            SectionList
          </Route>
          <Route path="/admin/sections/create" element={<SectionCreate />}>
            SectionCreate
          </Route>
          <Route path="/admin/categories" element={<CategoriesList />}>
            CategoriesList
          </Route>
          <Route path="/admin/categories/create" element={<CategoriesCreate />}>
            CategoriesCreate
          </Route>
          <Route path="/admin/subcategories" element={<SubcategoriesList />}>
            SubcategoriesList
          </Route>
          <Route
            path="/admin/subcategories/create"
            element={<SubcategoriesCreate />}
          >
            SubcategoriesCreate
          </Route>
          <Route
            path="/admin/subcategories/add-product"
            element={<AddProductToSubcategory />}
          >
            AddProductToSubcategory
          </Route>
          <Route
            path="/admin/subcategories/detailed"
            element={<SubcategoryDetailed />}
          >
            SubcategoryDetailed
          </Route>
          <Route path="/admin/warehouses" element={<WarehousesList />}>
            Warehouses
          </Route>
          <Route path="/admin/warehouses/create" element={<WarehousesCreate />}>
            Warehouses
          </Route>
          <Route path="/admin/suppliers" element={<SuppliersList />}>
            Suppliers
          </Route>
          <Route path="/admin/suppliers/create" element={<SuppliersCreate />}>
            Suppliers
          </Route>
          <Route path="/admin/billers" element={<BillersList />}>
            Billers
          </Route>
          <Route path="/admin/billers/create" element={<BillersCreate />}>
            Billers
          </Route>
          <Route path="/admin/stock" element={<StockList />}>
            Stock
          </Route>
          <Route path="/admin/stock/create" element={<StockCreate />}>
            Stock
          </Route>
          <Route path="/admin/stock/history" element={<StockHistory />}>
            Stock
          </Route>
          <Route path="/admin/stock/transfer" element={<StockTransfer />}>
            StockTransfer
          </Route>
          <Route
            path="/admin/stock/transfer_history"
            element={<TransferHistory />}
          >
            TransferHistory
          </Route>
          <Route path="/admin/stock/multi_transfer" element={<MultiTransfer />}>
            MultiTransfer
          </Route>
          <Route
            path="/admin/stock/auto_multi_transfer"
            element={<AutoMultiTransfer />}
          >
            AutoMultiTransfer
          </Route>
          <Route path="/admin/sales" element={<SalesList />}>
            SalesList
          </Route>
          <Route path="/admin/sales/cancelled" element={<CancelledSalesList />}>
            CancelledSalesList
          </Route>
          <Route path="/admin/sales/products" element={<SalesProducts />}>
            SalesProducts
          </Route>
          <Route path="/admin/sales/detail" element={<SalesDetail />}>
            SalesDetail
          </Route>
          <Route path="/admin/sales/view" element={<SaleView />}>
            SaleView
          </Route>
          <Route path="/admin/users" element={<UsersList />}>
            Users
          </Route>
          <Route path="/admin/tokens" element={<NotificationsList />}>
            Notifications
          </Route>
          <Route path="/admin/deliveryguys" element={<DeliveryGuysList />}>
            Delivery Guys
          </Route>
          <Route
            path="/admin/deliveryguys/create"
            element={<DeliveryGuysCreate />}
          >
            Delivery Guys
          </Route>
          <Route path="/admin/blog" element={<BlogList />}>
            Blog
          </Route>
          <Route path="/admin/blog/create" element={<BlogCreate />}>
            Blog
          </Route>
          <Route path="/admin/expenses" element={<ExpensesList />}>
            Expenses
          </Route>
          <Route path="/admin/expenses/create" element={<ExpensesCreate />}>
            Expenses
          </Route>
          <Route path="/admin/promotions" element={<List />}>
            Promotions
          </Route>
          <Route path="/admin/promotions/create" element={<PromotionsCreate />}>
            Promotions
          </Route>
          <Route path="/admin/alerts/expiry" element={<AlertPage />}>
            Alerts
          </Route>
          <Route path="/admin/alerts/inventory" element={<InventoryAlerts />}>
            InventoryAlerts
          </Route>
          <Route path="/admin/alerts/quantity" element={<QuantityAlerts />}>
            QuantityAlerts
          </Route>
          <Route
            path="/admin/alerts/subcategory"
            element={<SubcategoryAlerts />}
          >
            SubcategoryAlerts
          </Route>
          <Route path="/admin/alerts/image" element={<ImageAlerts />}>
            ImageAlerts
          </Route>
          <Route path="/admin/purchase_order" element={<PurchaseOrderList />}>
            PurchaseOrderList
          </Route>
          <Route
            path="/admin/purchase_order/detailed"
            element={<PurchaseOrderDetailed />}
          >
            PurchaseOrderList
          </Route>
          <Route
            path="/admin/purchase_order/manual"
            element={<PurchaseOrderManual />}
          >
            PurchaseOrderManual
          </Route>
          <Route
            path="/admin/purchase_order/automatic"
            element={<PurchaseOrderAutomatic />}
          >
            PurchaseOrderAutomatic
          </Route>
          <Route
            path="/admin/purchase_order/products"
            element={<PurchaseOrderProducts />}
          >
            PurchaseOrderProducts
          </Route>
          <Route
            path="/admin/purchase_order/view"
            element={<PurchaseOrderView />}
          >
            PurchaseOrderView
          </Route>
          <Route
            path="/admin/purchase_order/edit"
            element={<PurchaseOrderEdit />}
          >
            PurchaseOrderEdit
          </Route>
          <Route path="/admin/closure" element={<DynamicDayClosure />}>
            DynamicDayClosure
          </Route>
          <Route path="/admin/closure/day" element={<DayClosure />}>
            DayClosure
          </Route>
          <Route path="/admin/closure/list" element={<ClosureList />}>
            ClosureList
          </Route>
          <Route path="/admin/closure/mensuel" element={<MonthlyClosureList />}>
            MonthlyClosureList
          </Route>
          <Route path="/admin/clousure/detail" element={<ClosureDetail />}>
            ClosureDetail
          </Route>
          <Route path="/admin/expense/list" element={<HistoricExpenseList />}>
            HistoricExpenseList
          </Route>
          <Route path="/admin/fee/list" element={<HistoricFeeList />}>
            HistoricFeeList
          </Route>
          <Route path="/admin/settings" element={<SystemSettingsView />}>
            SystemSettingsView
          </Route>
          <Route path="/admin/adminaccounts" element={<AdminAccountsList />}>
            AdminAccountsList
          </Route>
          <Route path="/admin/admins/create" element={<AdminCreate />}>
            AdminCreate
          </Route>
          <Route
            path="/admin/contact_messages"
            element={<ContactMessagesList />}
          >
            ContactMessagesList
          </Route>
          <Route path="/admin/email_templates" element={<EmailTemplates />}>
            EmailTemplates
          </Route>
          <Route
            path="/admin/EmailTemplates/create"
            element={<EmailTemplatesCreate />}
          >
            EmailTemplatesCreate
          </Route>
          <Route path="/admin/biller/stats" element={<BillerStats />}>
            BillerStats
          </Route>
        </>
      )}
      <Route path="/admin" element={<Login />}>
        Login
      </Route>
      <Route path="/admin/login" element={<Login />}>
        Login
      </Route>

      <Route path="*" element={<LoginRedirect />} />
    </Routes>
  );
}
